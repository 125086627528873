<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-subcontractor' }">{{
            $t("MENU.ITEM.SUBC.SUBCONTRACTORS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>

          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSubcontractor"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <v-overlay :value="!firstLoader && isLoadingSubcontractor">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>

          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSubcontractor"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_SUBCONTRACTOR,
  UPDATE_SUBCONTRACTOR,
  DELETE_SUBCONTRACTOR,
} from "@/modules/subcontractors/store/subcontractor.module";
import { FETCH_SUBCONTRACTORS_TYPES } from "@/modules/subcontractors-types/store/subcontractor-type.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      subcontractor: {},
      subcontractor_type: [],
    };
  },
  components: {
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown,
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SUBC.SUBCONTRACTORS"),
        route: { name: "list-subcontractor" },
      },
      { title: this.$i18n.t("MENU.ITEM.EDIT") },
    ]);
    this.$store.dispatch(FETCH_SUBCONTRACTORS_TYPES).then((data) => {
      this.subcontractor_type = data.data.items;
    });
    vm.getSubcontractor();
  },
  computed: {
    ...mapGetters(["isLoadingSubcontractor"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "subcontractor-lastName-input",
            groupId: "subcontractor-lastName-group",
            required: "required",
            feedback_id: "subcontractor-live-feedback",
            divider: false,
            colSm: "4",
            i18n: {
              label: "MENU.ITEM.SUBC.COMPANY_NAME",
              placeholder: "MENU.ITEM.SUBC.COMPANY_NAME",
              validation: "MENU.ITEM.SUBC.COMPANY_NAME",
            },
            validations: {
              required: true,
              minLength: 2,
            },
            translatable: false,
            model: "companyName",
          },
          {
            type: "input",
            inputType: "text",
            id: "subcontractor-hourlyRate-input",
            groupId: "subcontractor-hourlyrate-group",
            required: "required",
            feedback_id: "subcontractor-live-feedback",
            divider: false,
            colSm: "4",
            i18n: {
              label: "MENU.ITEM.SUBC.ADDRESS",
              placeholder: "MENU.ITEM.SUBC.ADDRESS",
              validation: "MENU.ITEM.SUBC.ADDRESS",
            },
            validations: {},
            translatable: false,
            model: "address",
          },
          {
            type: "input",
            inputType: "text",
            id: "subcontractor-hourlyRate-input",
            groupId: "subcontractor-hourlyrate-group",
            required: "required",
            feedback_id: "subcontractor-live-feedback",
            divider: false,
            colSm: "4",
            i18n: {
              label: "MENU.ITEM.SUBC.EIK",
              placeholder: "MENU.ITEM.SUBC.EIK",
              validation: "MENU.ITEM.SUBC.EIK",
            },
            validations: {},
            translatable: false,
            model: "eik",
          },
          {
            type: "input",
            inputType: "text",
            id: "subcontractor-hourlyRate-input",
            groupId: "subcontractor-hourlyrate-group",
            required: "required",
            feedback_id: "subcontractor-live-feedback",
            divider: false,
            colSm: "3",
            i18n: {
              label: "MENU.ITEM.SUBC.MOL",
              placeholder: "MENU.ITEM.SUBC.MOL",
              validation: "MENU.ITEM.SUBC.MOL",
            },
            validations: {},
            translatable: false,
            model: "mol",
          },
          {
            type: "input",
            inputType: "text",
            id: "subcontractor-email-input",
            groupId: "subcontractor-firstName-group",
            required: "required",
            feedback_id: "subcontractor-live-feedback",
            divider: false,
            colSm: "3",
            i18n: {
              label: "MENU.ITEM.SUBC.EMAIL",
              placeholder: "MENU.ITEM.SUBC.EMAIL",
              validation: "FORM.VALID_EMAIL",
            },
            validations: {},
            translatable: false,
            model: "email",
          },
          {
            type: "input",
            inputType: "text",
            id: "subcontractor-phone-input",
            groupId: "subcontractor-firstName-group",
            required: "required",
            feedback_id: "subcontractor-live-feedback",
            divider: false,
            colSm: "3",
            i18n: {
              label: "MENU.ITEM.SUBC.PHONE",
              placeholder: "MENU.ITEM.SUBC.PHONE",
              validation: "FORM.VALID_PHONE",
            },
            validations: {},
            translatable: false,
            model: "phone",
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "3",
            divider: false,
            clearable: false,
            i18n: {
              label: "MENU.ITEM.SUBC.SUBCONTRACTOR_TYPE",
              placeholder: "MENU.ITEM.SUBC.SUBCONTRACTOR_TYPE",
            },
            options: this.subcontractor_type,
            validations: {
              required: true,
            },
            model: "subcontractorTypeId",
          },
        ],
      };
    },
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    getSubcontractor() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_SUBCONTRACTOR, id)
        .then((data) => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.subcontractor = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.subcontractor);
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found",
            });
            this.$router.push({ name: "list-subcontractor" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_SUBCONTRACTOR, {
          id: vm.subcontractor.id,
          payload: this.form,
        })
        .then((data) => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });

          if (next == "continue") {
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-subcontractor" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-subcontractor" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_SUBCONTRACTOR, vm.subcontractor.id)
            .then((data) => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message,
              });
              vm.$router.push({ name: "list-subcontractor" });
            })
            .catch((response) => {
              vm.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response.data.message,
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    },
  },
};
</script>
